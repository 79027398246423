import {
  ActionIcon,
  Badge,
  Button,
  Container,
  Group,
  Image,
  Modal,
  Paper,
  SimpleGrid,
  Switch,
  Text,
  TextInput
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconX } from '@tabler/icons'
import { DomainsRefetch } from 'components/DomainsRefetch/DomainsRefetch'
import { GrayButton } from 'components/GrayButton/GrayButton'

import { SiteFaviconSync } from 'syncComponents/SiteFaviconSync/SiteFaviconSync'
import { cabinetColors } from 'utils/cabinetTheme'

import { SyncSiteContextProvider, useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import Head from 'next/head'
import React, { useState } from 'react'
import { SiteMenu } from 'syncComponents/SiteMenu/SiteMenu'

import { ImagesSync } from 'syncComponents/ImageGallerySync/ImageGallerySync'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'

import { getPathToFile } from 'utils/getPathToFile'
import { trpc } from 'utils/trpc'

import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { IsLoggedUserWrap } from 'wrappers/IsLoggedUserWrap'

import { Favicon } from 'utils/Favicon'

export default function () {
  const { classes } = useMainStyles()
  return (
    <>
      <Head>
        <title>Настройки сайта</title>

        <Favicon />
      </Head>
      <IsLoggedUserWrap>
        <div
          css={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
          }}
          className={classes.hideScrollbar}
        >
          <PageSyncMemo />
        </div>
      </IsLoggedUserWrap>
    </>
  )
}

const PageSyncMemo = React.memo(
  () => {
    return (
      <SyncSiteContextProvider>
        <PageSync />
      </SyncSiteContextProvider>
    )
  },
  (prevProps, nextProps) => {
    return true
  }
)

export function PageSync() {
  const { siteID } = useSyncSiteContext()

  return (
    <>
      <SiteMenu activeTab="settings">
        <Container>
          <SiteSettingsSyncMemo />
        </Container>
      </SiteMenu>
    </>
  )
}

export const SiteSettingsSyncMemo = React.memo(SiteSettingsSync, () => true)

function SiteSettingsSync({ onClose }: { onClose?: () => void }) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const siteDomain = useGetSelectDataUndefined({ modelID: site.domain?.id, selectID: site.domain?.selectID })
  const paidPlace = useGetSelectData({ modelID: site.paidPlace?.id, selectID: site.paidPlace?.selectID })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const updateFieldSite = trpc.useMutation('user.site.updateFieldSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => { },
  })

  const updateFaviconSite = trpc.useMutation('user.site.updateFaviconSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => { },
  })
  const updateTemplatePictureSite = trpc.useMutation('user.site.updateTemplatePictureSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => { },
  })

  const updateDomainSite = trpc.useMutation('user.domain.updateDomainSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {
      setDomainsOpened(false)
    },
  })

  const createTarget = trpc.useMutation('user.yandexMetrika.createTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteTarget = trpc.useMutation('user.yandexMetrika.deleteTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [imagesOpened, setImagesOpened] = useState(false)
  const [domainsOpened, setDomainsOpened] = useState(false)

  const moveSiteToUser = trpc.useMutation('user.moveSiteToUser', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const moveSiteToUserForm = useForm({
    initialValues: {
      userLogin: '',
    },
  })
  const createTargetForm = useForm({
    initialValues: {
      name: '',
    },
  })

  const [imagesTemplateOpened, setImagesTemplateOpened] = useState(false)

  return (
    <>
      <Modal lockScroll={false} size={1000} opened={domainsOpened} onClose={() => setDomainsOpened(false)}>
        <DomainsRefetch
          onChooseDomain={({ domainID }) => {
            updateDomainSite.mutate({
              siteID,
              domainID,
            })
          }}
        />
      </Modal>

      {onClose ? (
        <Group position="right">
          <ActionIcon
            onClick={() => {
              if (onClose) onClose()
            }}
          >
            <IconX />
          </ActionIcon>
        </Group>
      ) : null}

      <Paper p="lg">
        <SimpleGrid
          css={{
            alignItems: 'flex-start',
            alignContent: 'start',
          }}
          breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
          cols={1}
          spacing={32}
        >
          <Group>
            <Text weight={600} size="xl" css={{ textTransform: 'uppercase' }}>
              Домен сайта:
            </Text>
            <Badge
              size="xl"
              css={{
                backgroundColor: `${siteDomain ? cabinetColors.accent : cabinetColors.placeholder} !important`,
                color: `${siteDomain ? cabinetColors.black : cabinetColors.black} !important`,
              }}
            >
              {siteDomain ? <>{siteDomain.domain} </> : 'Без домена'}
            </Badge>
            {siteDomain ? (
              <>
                <GrayButton
                  onClick={() => {
                    updateDomainSite.mutate({
                      siteID,
                      domainID: null,
                    })
                  }}
                >
                  Открепить от домена
                </GrayButton>
              </>
            ) : null}
            <GrayButton onClick={() => setDomainsOpened(true)}>Поменять домен</GrayButton>
          </Group>
          <SimpleGrid spacing={8} cols={1}>
            <Group>
              <Text weight={600} size="xl" css={{ textTransform: 'uppercase' }}>
                Фавиконка сайта:
              </Text>
              {site.favicon ? (
                <>
                  <SiteFaviconSync imageID={site.favicon.id} selectID={site.favicon.selectID} />

                  <GrayButton
                    onClick={() =>
                      updateFaviconSite.mutate({
                        siteID,
                        faviconID: null,
                      })
                    }
                  >
                    Убрать фавиконку
                  </GrayButton>
                  <GrayButton
                    onClick={() => {
                      setImagesOpened(true)
                    }}
                  >
                    Обновить фавиконку
                  </GrayButton>
                </>
              ) : (
                <GrayButton
                  onClick={() => {
                    setImagesOpened(true)
                  }}
                >
                  Выбрать фавиконку
                </GrayButton>
              )}
            </Group>
          </SimpleGrid>

          <Group>
            <TextInputWithUpdate
              value={site.name}
              onChange={value => {
                updateFieldSite.mutate({
                  siteID,
                  name: value,
                })
              }}
              label={'Название сайта'}
            />
          </Group>

          <form
            onSubmit={moveSiteToUserForm.onSubmit(async values => {
              moveSiteToUser.mutate({
                userLogin: values.userLogin,
                siteID: site.id,
              })
            })}
          >
            <Group css={{ alignItems: 'flex-end' }}>
              <TextInput
                required
                placeholder={'логин пользователя'}
                label={'Передать сайт пользователю'}
                {...moveSiteToUserForm.getInputProps('userLogin')}
              />
              <GrayButton type="submit" loading={moveSiteToUser.isLoading}>
                Передать сайт
              </GrayButton>
            </Group>
          </form>

          <Switch
            checked={site.withBlockGroups}
            onChange={event => {
              updateFieldSite.mutate({
                siteID,
                withBlockGroups: event.currentTarget.checked,
              })
            }}
            size="lg"
            label={'Наборы блоков'}
            onLabel={'Вкл.'}
            offLabel={'Выкл.'}
          />

          {user.isAdmin ? (
            <SimpleGrid spacing={8} cols={1}>
              <Switch
                checked={site.isTemplate}
                onChange={event => {
                  updateFieldSite.mutate({
                    siteID,
                    isTemplate: event.currentTarget.checked,
                  })
                }}
                size="lg"
                label={'Доступный всем'}
                onLabel={'Да.'}
                offLabel={'Нет.'}
              />
              {site.isTemplate ? (
                <>
                  <Group>
                    <Text>Обложка шаблона</Text>
                    {site.templatePicture ? (
                      <>
                        <SiteTemplatePicture imageID={site.templatePicture.id} selectID={site.templatePicture.selectID} />
                      </>
                    ) : (
                      <Text>Не выбрана</Text>
                    )}
                  </Group>
                  <Group>
                    {site.templatePicture ? (
                      <>
                        <GrayButton
                          onClick={() => {
                            setImagesTemplateOpened(true)
                          }}
                        >
                          Обновить обложку шаблона
                        </GrayButton>
                      </>
                    ) : (
                      <GrayButton
                        onClick={() => {
                          setImagesTemplateOpened(true)
                        }}
                      >
                        Выбрать обложку шаблона
                      </GrayButton>
                    )}
                  </Group>
                </>
              ) : undefined}
            </SimpleGrid>
          ) : (
            <></>
          )}

          <ImagesSync
            opened={imagesOpened}
            onClose={() => setImagesOpened(false)}
            imageChoosen={async ({ imageID, imageName }) => {
              updateFaviconSite.mutate({
                siteID,
                faviconID: imageID,
              })
              setImagesOpened(false)
            }}
          />

          <ImagesSync
            opened={imagesTemplateOpened}
            onClose={() => setImagesTemplateOpened(false)}
            imageChoosen={async ({ imageID, imageName }) => {
              updateTemplatePictureSite.mutate({
                siteID,
                imageID,
              })
              setImagesTemplateOpened(false)
            }}
          />
        </SimpleGrid>
      </Paper>
    </>
  )
}

function SiteTemplatePicture({ selectID, imageID }: { imageID: string; selectID: 'Image1' }) {
  const favicon = useGetSelectData({ modelID: imageID, selectID })

  return <Image width={'100%'} src={getPathToFile({ fileName: favicon.name, folderName: favicon.folder })} />
}
