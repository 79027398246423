import { arrayMove } from '@dnd-kit/sortable'
import { ActionIcon, Group, Menu, Modal, Paper, Popover, SimpleGrid, Text, Tooltip, createStyles } from '@mantine/core'
import { useFullscreen, useHotkeys, useIntersection } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { ScreeenType } from '@prisma/client'
import {
  IconArrowBackUp,
  IconArrowRight,
  IconArrowsHorizontal,
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconArrowsMoveVertical,
  IconBox,
  IconBoxOff,
  IconBrandPagekit,
  IconCirclePlus,
  IconColorPicker,
  IconDeviceDesktop,
  IconDeviceMobile,
  IconDeviceTablet,
  IconForms,
  IconLayoutList,
  IconMessageCircle,
  IconPencilPlus,
  IconPhoto,
  IconPinned,
  IconPinnedOff,
  IconPlugConnected,
  IconPlus,
  IconSettings,
  IconTextSize,
  IconVideo,
  IconX,
  IconZoomQuestion,
} from '@tabler/icons'
import { AccentButton } from 'components/AccentButton/AccentButton'
import { GrayButton } from 'components/GrayButton/GrayButton'

import { AddFreeLayer } from 'components/AddFreeLayer/AddFreeLayer'
import { BlockCollections, MySiteBlockCollections } from 'components/BlockCollections/BlockCollections'
import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import { VinglaSupport } from 'components/VinglaSupport/VinglaSupport'
import { breakpointsBox } from 'config/mq'
import { MyBoxBlockContext } from 'contexts/BoxBlockContext'
import { SyncSiteContextProvider, useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { getDataAtStoreSite } from 'generation/synchronFrontendAtSite'
import Head from 'next/head'
import { useRouter } from 'next/router'

import React, { RefObject, useEffect, useRef, useState } from 'react'
import { List } from 'react-movable'
import useStateRef from 'react-usestateref'
import { NestedBoxSelect } from 'server/selects'
import { BlockGroupsSync } from 'syncComponents/BlockGroupsSync/BlockGroupsSync'
import { SiteBlocksSync } from 'syncComponents/SiteBlocksSync/SiteBlocksSync'
import { SitePagePathSync, SitePagesMemo } from 'syncComponents/SitePages/SitePages'
import { SiteTablesSync } from 'syncComponents/SiteTablesSync/SiteTablesSync'

import { BoxSettingsSync } from 'syncComponents/BoxSettingsSync/BoxSettingsSync'
import { BoxSyncMemoV2 } from 'syncComponents/BoxSync/BoxSyncV2'
import { ChooseSiteColorsSync } from 'syncComponents/ChooseSiteColorsSync/ChooseSiteColorsSync'
import { ImagesSync } from 'syncComponents/ImageGallerySync/ImageGallerySync'
import { LayerSettingsSyncMemo } from 'syncComponents/LayerSettingsSync/LayerSettingsSync'
import { popupActiveIDCenter } from 'syncComponents/PagePopupsSync/PagePopupsSync'
import { PopupBoxEditorSync } from 'syncComponents/PopupBoxEditorSync/PopupBoxEditorSync'
import { SiteFormsSync } from 'syncComponents/SiteFormsSync/SiteFormsSync'
import { SiteQuizzesSync } from 'syncComponents/SiteQuizzesSync/SiteQuizzesSync'
import { VINGLA } from 'utils/VINGLA'
import { activeResponsiveModeCenter, useCurrentResponsiveMode } from 'utils/activeResponsiveModeCenter'
import { boxActiveIDCenter } from 'utils/boxActiveIDCenter'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'
import { useServiceState } from 'utils/useServiceState'

import { IsLoggedUserWrap } from 'wrappers/IsLoggedUserWrap'

import { css } from '@emotion/react'
import { Favicon } from 'utils/Favicon'
import { animationEmotionBounce } from 'utils/animationEmotionBounce'
import { SiteIntergrationsSyncMemo } from '../intergrations'
import { SiteLeadsMemo } from '../leads'
import { SiteSettingsSyncMemo } from '../settings'

export default function () {
  return (
    <>
      <Head>
        <title>Редактирование страницы</title>

        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, user-scalable=0" />

        <Favicon />
      </Head>
      <IsLoggedUserWrap fullWidth={true}>
        <PageMemo></PageMemo>
      </IsLoggedUserWrap>
    </>
  )
}

const PageMemo = React.memo(
  () => {
    return (
      <SyncSiteContextProvider>
        <Page />
      </SyncSiteContextProvider>
    )
  },
  (prevProps, nextProps) => {
    return true
  }
)

export const useMainStyles = createStyles((theme, _params, getRef) => ({
  hideScrollbar: {
    '::-webkit-scrollbar': {
      // display: 'none',
      width: 0,
      height: 0,
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.blue,
    },
  },
}))

const findNestedBox = (box: NestedBoxSelect, boxID: string) => {
  if (box.id == boxID) {
    return box
  } else {
    let nestedBox: NestedBoxSelect | undefined
    box.boxes?.map(box => {
      if (typeof nestedBox == 'undefined') nestedBox = findNestedBox(box, boxID)
    })
    return nestedBox
  }
}

const SectionsPinnedCollection = () => {
  return <></>
}

export function Page() {
  const { siteID } = useSyncSiteContext()
  const router = useRouter()
  const { pageID } = router.query
  const page = useGetSelectData({ modelID: `${pageID}`, selectID: 'Page1' })
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const { classes } = useMainStyles()

  const [width, setWidth, widthRef] = useStateRef<number>(0)
  const [widthResponsive, setWidthResponsive, widthResponsiveRef] = useStateRef<number | undefined>(1920)
  const [widthTemporaryResponsive, setWidthTemporaryResponsive, widthTemporaryResponsiveRef] = useStateRef<number | undefined>(
    undefined
  )

  useHotkeys([
    [
      'backspace',
      () => {
        boxActiveIDCenter.delete()
      },
    ],
  ])

  useEffect(() => {
    const keyDownCallback = (event: KeyboardEvent) => {
      if (event.shiftKey) {
        useServiceState.setState({ isShiftDown: true })
        console.log('shift down')
      }
    }

    const keyUpCallback = (event: KeyboardEvent) => {
      if (!event.shiftKey) {
        useServiceState.setState({ isShiftDown: false })
        console.log('shift up')
      }
      if (event.key == 'Enter') {
        boxActiveIDCenter.addInside()
      }
    }

    document.body.addEventListener('keydown', keyDownCallback)
    document.body.addEventListener('keyup', keyUpCallback)
    return () => {
      document.body.removeEventListener('keydown', keyDownCallback)
      document.body.removeEventListener('keyup', keyUpCallback)
    }
  }, [])

  const createBoxBlockWithPagePinAndSectionCreate = trpc.useMutation('user.box.createBoxBlockWithPagePinAndSectionCreate', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const generateSections = trpc.useMutation('user.box.generateSections', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const boxesRef = useRef<HTMLDivElement>(null)
  const boxesWidthRef = useRef<HTMLDivElement>(null)
  const boxesShellRef = useRef<HTMLDivElement>(null)

  const [lineIsShow, setLineIsShow] = useState(true)

  useEffect(() => {
    if (boxesRef.current) {
      setWidth(boxesRef.current.offsetWidth)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (boxesRef.current) {
          const width = Math.ceil(boxesRef.current.offsetWidth)
          if (width != widthRef.current) setWidth(width)
        }
      })
      resizeObserver.observe(boxesRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  const responsiveMode = useCurrentResponsiveMode()

  useEffect(() => {
    activeResponsiveModeCenter.setResponsiveMode({ responsiveMode: 'Desktop' })
  }, [])

  useEffect(() => {
    let newResponsiveMode: ScreeenType
    if (widthRef.current >= breakpointsBox.desktop) {
      newResponsiveMode = 'Desktop'
    } else if (widthRef.current >= breakpointsBox.tablet) {
      newResponsiveMode = 'Tablet'
    } else {
      newResponsiveMode = 'Mobile'
    }
    activeResponsiveModeCenter.setResponsiveMode({ responsiveMode: newResponsiveMode })
  }, [width])

  useEffect(() => {
    if (boxesWidthRef.current) setWidthResponsive(boxesWidthRef.current.offsetWidth)
  }, [])

  const dragWidthPX = 30

  const settingsWidth = 0 // 290
  const layersWidth = 0 // 290

  const [sectionsPinnedCollection, setSectionsPinnedCollection, sectionsPinnedCollectionRef] = useStateRef(
    page.sectionsPinnedCollection
  )

  useEffect(() => {
    setSectionsPinnedCollection(page?.sectionsPinnedCollection)
  }, [page.sectionsPinnedCollection])

  const updateSectionsPinned = trpc.useMutation('user.updateSectionsPinned', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateSectionsPinnedSync = trpc.useMutation('user.updateSectionsPinnedSync', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteSectionsPinned = trpc.useMutation('user.deleteSectionsPinned', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createSectionsPinned = trpc.useMutation('user.createSectionsPinned', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createSectionsPinnedWithBoxBlock = trpc.useMutation('user.createSectionsPinnedWithBoxBlock', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const copyFavoriteBoxBlock = trpc.useMutation('user.blockCollections.copyFavoriteBoxBlock', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [chooseSectionsOpened, setChooseSectionsOpened] = useState(false)

  const boxHeightRef = useRef<HTMLDivElement>(null)
  const boxHeightBottomRef = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(0)
  const [heightBottom, setHeightBottom] = useState(0)

  useEffect(() => {
    if (boxHeightRef.current && boxHeightBottomRef.current) {
      setHeight(boxHeightRef.current.offsetHeight)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (boxHeightRef.current) setHeight(boxHeightRef.current.offsetHeight)
      })
      resizeObserver.observe(boxHeightRef.current)

      setHeightBottom(boxHeightBottomRef.current.offsetHeight)
      const resizeObserverBottom: ResizeObserver = new ResizeObserver(() => {
        if (boxHeightBottomRef.current) setHeightBottom(boxHeightBottomRef.current.offsetHeight)
      })
      resizeObserverBottom.observe(boxHeightBottomRef.current)
      return () => {
        resizeObserver.disconnect()
        resizeObserverBottom.disconnect()
      }
    }
  }, [])

  const entitiesRoutes: { name: string; path: string }[] = [
    { name: 'Секции', path: 'sections' },
    { name: 'Блоки', path: 'blocks' },
    { name: 'Формы', path: 'forms' },
    { name: 'Квизы', path: 'quizzes' },
    { name: 'Всплывашки', path: 'popups' },
    { name: 'События', path: 'events' },
    { name: 'Цели', path: 'targets' },
    { name: 'Цвета', path: 'colors' },
  ]

  const [focusBoxID, setFocusBoxID] = useState('')
  const [focusBoxBlockID, setFocusBoxBlockID] = useState('')

  const [sectionsOpened, setSectionsOpened] = useState(false)
  const [blocksOpened, setBlocksOpened] = useState(false)
  const [eventsOpened, setEventsOpened] = useState(false)
  const [formsOpened, setFormsOpened] = useState(false)
  const [popupsOpened, setPopupsOpened] = useState(false)
  const [quizzessOpened, setQuizzessOpened] = useState(false)

  const [targetsOpened, setTargetsOpened] = useState(false)

  const { toggle, fullscreen } = useFullscreen()

  const pinBoxBlockFavorite = trpc.useMutation('user.box.pinBoxBlockFavorite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const tabHeight = 44
  const responsiveHeight = 20

  const [widthIsDrag, setWidthIsDrag] = useState(false)

  const [value, setValue] = useState({
    lineIsShow,
    setLineIsShow,
    responsiveMode: ScreeenType.Desktop,
    setResponsiveMode: (c: ScreeenType) => { },
    focusBoxID: focusBoxID,
    setFocusBoxID,
    focusBoxBlockID,
    setFocusBoxBlockID,
  })

  useEffect(() => {
    return () => {
      boxActiveIDCenter.setActiveID({ boxID: undefined })
      popupActiveIDCenter.setActiveID({ popupID: undefined })
    }
  }, [])

  const [activeBoxesID, setActiveBoxesID] = useState<string[]>([])
  const [activePopupID, setActivePopupID] = useState<string | undefined>()

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [sitePagesIsOpen, setSitePagesIsOpen] = useState(false)
  const [siteSettingsIsOpen, setSiteSettingsIsOpen] = useState(false)

  const [siteLeadsIsOpen, setSiteLeadsIsOpen] = useState(false)

  const [siteMenuIsOpen, setSiteMenuIsOpen] = useState<
    'settings' | 'colors' | 'pages' | 'forms' | 'quizzess' | 'leads' | 'tables' | 'intergrations' | undefined
  >(undefined)

  useEffect(() => {
    const unlisten = popupActiveIDCenter.listener({
      callback: ({ popupID }) => {
        setActivePopupID(popupID)
      },
    })
    return () => {
      unlisten()
    }
  }, [])

  useEffect(() => {
    const unlisten = boxActiveIDCenter.listener({
      callback: ({ ids }) => {
        setActiveBoxesID(ids)
      },
    })
    return () => {
      unlisten()
    }
  }, [])

  const containerRef = useRef<HTMLDivElement>(null)

  const pageSectionsRef = useRef<HTMLDivElement>(null)

  const [resposiveModeIsDrag, setResposiveModeIsDrag] = useState(activeResponsiveModeCenter.getIsDrag())

  useEffect(() => {
    const { unlisten } = activeResponsiveModeCenter.subscribe({
      callbackIsDrag: ({ isDrag }) => {
        setResposiveModeIsDrag(isDrag)
      },
      callback: () => { },
    })
    return () => {
      unlisten()
    }
  }, [])

  const table = useGetSelectDataUndefined({ modelID: page?.pinTable?.id, selectID: page?.pinTable?.selectID })
  let tableDataIDWithSomeRows: string | undefined = undefined
  table?.pinnedTableData?.map(tableData => {
    const fountTableData = getDataAtStoreSite({ modelID: tableData.id, selectID: tableData.selectID })

    if (!tableDataIDWithSomeRows && fountTableData.rows.length) tableDataIDWithSomeRows = fountTableData.id
  })
  const tableData = useGetSelectDataUndefined({
    modelID: tableDataIDWithSomeRows,
    selectID: tableDataIDWithSomeRows ? 'TableDataPinnedToPage' : undefined, // костыль table?.pinnedTableData[0]?.selectID
  })

  const tableFirstRow = useGetSelectDataUndefined({
    modelID: tableData?.rows[0]?.id,
    selectID: tableData?.rows[0]?.selectID,
  })

  const cacheSite = trpc.useMutation('user.site.cacheSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => { },
  })


  const [currentBlocks, setCurrentBlocks] = useState<string>('site')

  if (page) {
    return (
      <>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
          }}
        >
          {/* {sectionsOpened ? (
          <Drawer
            transitionDuration={0}
            position="bottom"
            size="calc(100vh - 50px)"
            css={{ overflowY: 'auto' }}
            opened={sectionsOpened}
            onClose={() => setSectionsOpened(false)}
          >
            <SiteSections />
          </Drawer>
        ) : null} */}
          {/* {blocksOpened ? (
          <Modal transitionDuration={0} opened={blocksOpened} onClose={() => setBlocksOpened(false)}>
            <SiteBlocks />
          </Modal>
        ) : null}
        
        {eventsOpened ? (
          <Modal transitionDuration={0} size="xl" opened={eventsOpened} onClose={() => setEventsOpened(false)}>
            <SiteEvents />
          </Modal>
        ) : null}
        {popupsOpened ? (
          <Modal transitionDuration={0} size="xl" opened={popupsOpened} onClose={() => setPopupsOpened(false)}>
            <SitePopups />
          </Modal>
        ) : null}
        {quizzessOpened ? (
          <Modal transitionDuration={0} size="md" opened={quizzessOpened} onClose={() => setQuizzessOpened(false)}>
            <SiteQuizzes />
          </Modal>
        ) : null}
        {targetsOpened ? (
          <Modal transitionDuration={0} size="xl" opened={targetsOpened} onClose={() => setTargetsOpened(false)}>
            <SiteTargets />
          </Modal>
        ) : null} */}
          <div
            css={
              {
                // padding: '4px'
              }
            }
            ref={boxHeightRef}
          >
            {/* <div
              css={{
                width: `calc(100% - ${settingsWidth}px - ${layersWidth}px)`,
                margin: '0 auto',
                padding: '0 8px',
              }}
            >
            </div> */}
            <div
              css={{
                width: `100%`,
                minHeight: tabHeight,
                background: colors.blue,
                display: 'flex',
                alignItems: 'center',
                padding: '6px 8px',
              }}
            >
              <Group
                css={{
                  width: '100%',
                }}
                position="apart"
              >
                <Group>
                  <VINGLA small={true} white={true} />
                  <IconArrowBackUp
                    css={{ cursor: 'pointer' }}
                    onClick={() => router.push(`/panel/sitesV2/${site.id}`)}
                    width={20}
                    color={'white'}
                  />
                  {1 ? null : (
                    <>
                      <Tooltip position="bottom" label={'СТРАНИЦЫ'}>
                        <Popover
                          closeOnClickOutside={false}
                          onClose={() => {
                            setSiteMenuIsOpen(undefined)
                          }}
                          opened={siteMenuIsOpen == 'pages'}
                          zIndex={1000000}
                          width={1000}
                          position="top-start"
                          shadow="md"
                          offset={12}
                        >
                          <Popover.Target>
                            <div
                              css={{
                                cursor: 'pointer',
                                '& > svg': {
                                  display: 'block',
                                },
                              }}
                            >
                              <IconBrandPagekit
                                onClick={() => {
                                  setSiteMenuIsOpen(o => (o !== 'pages' ? 'pages' : undefined))
                                }}
                                color={'white'}
                              />
                            </div>
                          </Popover.Target>
                          <Popover.Dropdown
                            css={{
                              maxHeight: 'calc(100vh - 100px)',
                              overflowY: 'auto',
                            }}
                            className={classes.hideScrollbar}
                          >
                            <SitePagesMemo
                              onClose={() => {
                                setSiteMenuIsOpen(undefined)
                              }}
                            />
                          </Popover.Dropdown>
                        </Popover>
                      </Tooltip>

                      <Tooltip position="bottom" label={'CMS - Контент'}>
                        <Popover
                          closeOnClickOutside={false}
                          onClose={() => {
                            setSiteMenuIsOpen(undefined)
                          }}
                          opened={siteMenuIsOpen == 'tables'}
                          zIndex={1000000}
                          width={1000}
                          position="top-start"
                          shadow="md"
                          offset={12}
                        >
                          <Popover.Target>
                            <div
                              css={{
                                cursor: 'pointer',
                                '& > svg': {
                                  display: 'block',
                                },
                              }}
                            >
                              <IconLayoutList
                                onClick={() => {
                                  setSiteMenuIsOpen(o => (o !== 'tables' ? 'tables' : undefined))
                                }}
                                color={'white'}
                              />
                            </div>
                          </Popover.Target>
                          <Popover.Dropdown
                            css={{
                              maxHeight: 'calc(100vh - 100px)',
                              overflowY: 'auto',
                            }}
                            className={classes.hideScrollbar}
                          >
                            <SiteTablesSync />
                          </Popover.Dropdown>
                        </Popover>
                      </Tooltip>

                      <Tooltip position="bottom" label={'ЗАЯВКИ'}>
                        <Popover
                          closeOnClickOutside={false}
                          onClose={() => {
                            setSiteMenuIsOpen(undefined)
                          }}
                          opened={siteMenuIsOpen == 'leads'}
                          offset={12}
                          zIndex={1000000}
                          width={1000}
                          position="top-start"
                          shadow="md"
                        >
                          <Popover.Target>
                            <div
                              css={{
                                cursor: 'pointer',
                                '& > svg': {
                                  display: 'block',
                                },
                              }}
                            >
                              <IconMessageCircle
                                onClick={() => {
                                  setSiteMenuIsOpen(o => (o !== 'leads' ? 'leads' : undefined))
                                }}
                                color={'white'}
                              />
                            </div>
                          </Popover.Target>
                          <Popover.Dropdown
                            css={{
                              maxHeight: 'calc(100vh - 100px)',
                              overflowY: 'auto',
                            }}
                            className={classes.hideScrollbar}
                          >
                            <SiteLeadsMemo
                              onClose={() => {
                                setSiteMenuIsOpen(undefined)
                              }}
                            />
                          </Popover.Dropdown>
                        </Popover>
                      </Tooltip>

                      <Tooltip position="bottom" label={'НАСТРОЙКИ'}>
                        <Popover
                          closeOnClickOutside={false}
                          onClose={() => {
                            setSiteMenuIsOpen(undefined)
                          }}
                          opened={siteMenuIsOpen == 'settings'}
                          offset={12}
                          zIndex={1000000}
                          width={1000}
                          position="top-start"
                          shadow="md"
                        >
                          <Popover.Target>
                            <div
                              css={{
                                cursor: 'pointer',
                                '& > svg': {
                                  display: 'block',
                                },
                              }}
                            >
                              <IconSettings
                                onClick={() => {
                                  setSiteMenuIsOpen(o => (o !== 'settings' ? 'settings' : undefined))
                                }}
                                color={'white'}
                              />
                            </div>
                          </Popover.Target>
                          <Popover.Dropdown
                            css={{
                              maxHeight: 'calc(100vh - 100px)',
                              overflowY: 'auto',
                            }}
                            className={classes.hideScrollbar}
                          >
                            <SiteSettingsSyncMemo
                              onClose={() => {
                                setSiteMenuIsOpen(undefined)
                              }}
                            />
                          </Popover.Dropdown>
                        </Popover>
                      </Tooltip>

                      <Tooltip position="bottom" label={'ИНТЕГРАЦИИ'}>
                        <Popover
                          closeOnClickOutside={false}
                          onClose={() => {
                            setSiteMenuIsOpen(undefined)
                          }}
                          opened={siteMenuIsOpen == 'intergrations'}
                          offset={12}
                          zIndex={1000000}
                          width={1000}
                          position="top-start"
                          shadow="md"
                        >
                          <Popover.Target>
                            <div
                              css={{
                                cursor: 'pointer',
                                '& > svg': {
                                  display: 'block',
                                },
                              }}
                            >
                              <IconPlugConnected
                                onClick={() => {
                                  setSiteMenuIsOpen(o => (o !== 'intergrations' ? 'intergrations' : undefined))
                                }}
                                color={'white'}
                              />
                            </div>
                          </Popover.Target>
                          <Popover.Dropdown
                            css={{
                              maxHeight: 'calc(100vh - 100px)',
                              overflowY: 'auto',
                            }}
                            className={classes.hideScrollbar}
                          >
                            <SiteIntergrationsSyncMemo
                              onClose={() => {
                                setSiteMenuIsOpen(undefined)
                              }}
                            />
                          </Popover.Dropdown>
                        </Popover>
                      </Tooltip>

                      <Tooltip position="bottom" label={'ФОРМЫ'}>
                        <Popover
                          closeOnClickOutside={false}
                          onClose={() => {
                            setSiteMenuIsOpen(undefined)
                          }}
                          opened={siteMenuIsOpen == 'forms'}
                          offset={12}
                          zIndex={1000000}
                          width={1000}
                          position="top-start"
                          shadow="md"
                        >
                          <Popover.Target>
                            <div
                              css={{
                                cursor: 'pointer',
                                '& > svg': {
                                  display: 'block',
                                },
                              }}
                            >
                              <IconForms
                                onClick={() => {
                                  setSiteMenuIsOpen(o => (o !== 'forms' ? 'forms' : undefined))
                                }}
                                color={'white'}
                              />
                            </div>
                          </Popover.Target>
                          <Popover.Dropdown
                            css={{
                              maxHeight: 'calc(100vh - 100px)',
                              overflowY: 'auto',
                            }}
                            className={classes.hideScrollbar}
                          >
                            <SiteFormsSync
                              onClose={() => {
                                setSiteMenuIsOpen(undefined)
                              }}
                            />
                          </Popover.Dropdown>
                        </Popover>
                      </Tooltip>

                      <Tooltip position="bottom" label={'КВИЗЫ'}>
                        <Popover
                          closeOnClickOutside={false}
                          onClose={() => {
                            setSiteMenuIsOpen(undefined)
                          }}
                          opened={siteMenuIsOpen == 'quizzess'}
                          offset={12}
                          zIndex={1000000}
                          width={1000}
                          position="top-start"
                          shadow="md"
                        >
                          <Popover.Target>
                            <div
                              css={{
                                cursor: 'pointer',
                                '& > svg': {
                                  display: 'block',
                                },
                              }}
                            >
                              <IconZoomQuestion
                                onClick={() => {
                                  setSiteMenuIsOpen(o => (o !== 'quizzess' ? 'quizzess' : undefined))
                                }}
                                color={'white'}
                              />
                            </div>
                          </Popover.Target>
                          <Popover.Dropdown
                            css={{
                              maxHeight: 'calc(100vh - 100px)',
                              overflowY: 'auto',
                            }}
                            className={classes.hideScrollbar}
                          >
                            <SiteQuizzesSync
                              onClose={() => {
                                setSiteMenuIsOpen(undefined)
                              }}
                              isModal={true}
                            />
                          </Popover.Dropdown>
                        </Popover>
                      </Tooltip>

                      <ChooseSiteColorsSync inversion={true} onChooseColor={() => { }}>
                        <div
                          css={{
                            cursor: 'pointer',
                            '& > svg': {
                              display: 'block',
                            },
                          }}
                        >
                          <IconColorPicker
                            onClick={() => {
                              setSiteMenuIsOpen(undefined)
                            }}
                            color={'white'}
                          />
                        </div>
                      </ChooseSiteColorsSync>
                    </>
                  )}

                  <VinglaSupport white={true} />
                </Group>

                <AddFreeLayer />
                {activeBoxesID.length == 1 ? <AddSettingsAtPanelSync boxID={activeBoxesID[0]} /> : null}
                {/* <div
                        css={{
                          background: `rgba(255,255,255, 0.1)`,
                          borderRadius: '3px',
                          padding: '4px 7px',
                          cursor: 'pointer',
                          transition: '0.1s',
                          ':hover': {
                            background: `rgba(255,255,255, 0.4)`,
                            transform: 'scale(1.05)',
                          },
                        }}
                        onClick={() => {
                          setLineIsShow(lineIsShow => !lineIsShow)
                        }}
                      >
                        <Text color={'white'} size={'xs'}>
                          {lineIsShow ? 'Скрыть линии' : 'Показать линии'}
                        </Text>
                      </div> */}
                <Group
                  css={{
                    alignItems: 'center',
                  }}
                  spacing={7}
                >
                  <Group
                    onClick={() => setWidthResponsive(499)}
                    css={{
                      background: `rgba(255,255,255, ${responsiveMode == 'Mobile' ? '0.3' : '0.1'})`,
                      borderRadius: '3px',
                      padding: '4px 7px',
                      cursor: 'pointer',
                      ':hover': {
                        background: `rgba(255,255,255, ${responsiveMode == 'Mobile' ? '0.4' : '0.2'})`,
                      },
                    }}
                    spacing={6}
                  >
                    {/* <div
                            css={{
                              background: 'rgba(255,255,255, 0.1)',
                              padding: 3,
                              width: 23,
                              height: 23,
                            }}
                          > */}
                    <IconDeviceMobile
                      css={{
                        display: 'inline-block',
                      }}
                      size={18}
                      color={'white'}
                    />
                    {/* </div> */}
                    {/* <Text color={'white'} size={'xs'}>
                            499px {'<'}
                          </Text> */}
                  </Group>

                  <Group
                    onClick={() => setWidthResponsive(1199)}
                    css={{
                      background: `rgba(255,255,255, ${responsiveMode == 'Tablet' ? '0.3' : '0.1'})`,
                      borderRadius: '3px',
                      padding: '4px 7px',
                      cursor: 'pointer',
                      ':hover': {
                        background: `rgba(255,255,255, ${responsiveMode == 'Tablet' ? '0.4' : '0.2'})`,
                      },
                    }}
                    spacing={6}
                  >
                    {/* <div
                            css={{
                              background: 'rgba(255,255,255, 0.1)',
                              padding: 3,
                              width: 23,
                              height: 23,
                            }}
                          > */}
                    <IconDeviceTablet
                      css={{
                        display: 'inline-block',
                      }}
                      size={18}
                      color={'white'}
                    />
                    {/* </div> */}
                    {/* <Text color={'white'} size={'xs'}>
                            {'>='} 500px - 1199px {'<'}
                          </Text> */}
                  </Group>

                  <Group
                    onClick={() => setWidthResponsive(1920)}
                    css={{
                      background: `rgba(255,255,255, ${responsiveMode == 'Desktop' ? '0.3' : '0.1'})`,
                      borderRadius: '3px',
                      padding: '4px 7px',
                      cursor: 'pointer',
                      ':hover': {
                        background: `rgba(255,255,255, ${responsiveMode == 'Desktop' ? '0.4' : '0.2'})`,
                      },
                    }}
                    spacing={6}
                  >
                    {/* <div
                            css={{
                              background: 'rgba(255,255,255, 0.1)',
                              padding: 3,
                              width: 23,
                              height: 23,
                            }}
                          > */}
                    <IconDeviceDesktop
                      css={{
                        display: 'inline-block',
                      }}
                      size={18}
                      color={'white'}
                    />
                    {/* </div> */}
                    {/* <Text color={'white'} size={'xs'}>
                            {'>='} 1200px
                          </Text> */}
                  </Group>

                  <Text
                    css={{
                      fontWeight: 'bold',
                      minWidth: 100,
                    }}
                    color={'white'}
                  >
                    {' - '}
                    {widthResponsive
                      ? `${widthTemporaryResponsive ? widthTemporaryResponsive : widthResponsive} px`
                      : null}{' '}
                  </Text>
                </Group>

                {0 ? ( // user.isAdmin
                  <Group
                    title={'Сгенерировать по теме бизнеса'}
                    onClick={async () => {
                      const topic = prompt('Введите ваш бизнес, например "услуги маникюра"')
                      const sections = prompt(
                        'Введите через запятую какие блоки добавить, например: "отзывы, услуги, контакты, портфолио, акции, цены, преимущества, о нас, ..."'
                      )
                      const footerAndHeader = confirm('Создать хеадер и подвал?')
                      if (topic) {
                        await generateSections.mutateAsync({
                          topic,
                          pageID: page.id,
                          siteID: site.id,
                          sections: sections || '',
                          footerAndHeader,
                        })
                        setChooseSectionsOpened(false)
                      }
                    }}
                    css={{
                      background: `rgba(255,255,255, ${responsiveMode == 'Mobile' ? '0.3' : '0.1'})`,
                      borderRadius: '3px',
                      padding: '4px 7px',
                      cursor: 'pointer',
                      ':hover': {
                        background: `rgba(255,255,255, ${responsiveMode == 'Mobile' ? '0.4' : '0.2'})`,
                      },
                    }}
                  >
                    {generateSections.isLoading ? (
                      <Text size={'sm'} color="white">
                        Идет генерация...
                      </Text>
                    ) : (
                      <IconPencilPlus
                        css={{
                          display: 'inline-block',
                        }}
                        size={18}
                        color={'white'}
                      />
                    )}
                  </Group>
                ) : null}
                <Group
                  position="right"
                  css={{
                    alignItems: 'center',
                    // width: layersWidth,
                  }}
                >

                  <AccentButton
                    loading={cacheSite.isLoading}
                    onClick={() => {
                      cacheSite.mutate({
                        siteID,
                      })
                    }}
                    size="xs"
                  >
                    ОПУБЛИКОВАТЬ
                  </AccentButton>
                  {site.domain ? (
                    <SitePagePathSync
                      pagePathName={page.pathname}
                      color={'white'}
                      domainID={site.domain.id}
                      selectID={site.domain.selectID}
                      isID={tableFirstRow?.slug || !!page?.pinTable}
                    />
                  ) : null}
                  <div
                    css={{
                      cursor: 'pointer',
                    }}
                  >
                    {fullscreen ? (
                      <IconArrowsMinimize
                        color={'white'}
                        css={{
                          display: 'block',
                        }}
                        onClick={toggle}
                        size={20}
                      />
                    ) : (
                      <IconArrowsMaximize
                        color={'white'}
                        css={{
                          display: 'block',
                        }}
                        onClick={toggle}
                        size={20}
                      />
                    )}
                  </div>
                </Group>
                {/* <div>
                      {site.domain ? (
                        <a
                          target={'_blank'}
                          href={`${site.domain.domain.includes('localhost') ? 'http' : 'https'}://${site.domain.domain}/${
                            page.pathname
                          }`}
                          css={{
                            ':hover': {
                              opacity: 0.5,
                            },
                            textDecoration: 'none',
                          }}
                        >
                          <Group spacing={6}>
                            <Text
                              css={{
                                fontWeight: 'bold',
                              }}
                              color={'white'}
                              size={'xs'}
                            >
                              {site.domain.domain}/{page.pathname}
                            </Text>
                            <IconExternalLink width={16} color="white" />
                          </Group>
                        </a>
                      ) : null}
                    </div> */}
              </Group>
            </div>
          </div>
          <div
            style={{
              height: `calc(100% - ${height + heightBottom}px)`,
            }}
          >
            <MyBoxBlockContext.Provider value={value}>
              <div
                ref={boxesShellRef}
                style={{
                  width: '100%',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  maxHeight: '100%',
                  // borderTop: '1px solid #EFEFEF',
                }}
              >
                {/* <div
                  className={classes.hideScrollbar}
                  css={{
                    width: layersWidth,
                    padding: '8px 0',
                    height: 'calc(100%)',
                    // padding: 12,
                    overflowY: 'auto',
                    // borderTop: '1px solid #EFEFEF',
                  }}
                >
                  <div>
                    {page.sectionsPinnedCollection.map(sectionsPinnedCollection => {
                    return sectionsPinnedCollection.sectionsCollection.sectionsChild.map(sectionChild => {
                      return sectionChild.boxBlock ? (
                        <LayerBox
                          boxBlock={sectionChild.boxBlock}
                          boxBlockID={sectionChild.boxBlock.id}
                          key={sectionChild.id}
                          indent={1}
                          box={sectionChild.boxBlock.box}
                        />
                      ) : undefined
                    })
                  })}
                  </div>
                </div> */}

                <div
                  style={{
                    width: `calc(100% - ${settingsWidth}px - ${layersWidth}px)`,
                    height: '100%',
                    maxHeight: '100%',
                    position: 'relative',
                    boxShadow:
                      '0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 22px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px',
                    // borderRadius: '0 0 4px 4px',
                    // border: '1px solid #EFEFEF',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      width: `100%`,
                      height: responsiveHeight,
                      // background: '#3791f0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom: '1px solid #EFEFEF',
                    }}
                  >
                    <div
                      style={{
                        width: dragWidthPX,
                        minWidth: dragWidthPX,
                        borderLeft: '1px solid #EFEFEF',
                        borderRight: '1px solid #EFEFEF',
                        height: '100%',
                        // background: colors.blue,
                      }}
                    >
                      <DragOffsetPosition
                        limited={true}
                        onStart={() => {
                          activeResponsiveModeCenter.setIsDrag({ isDrag: true })
                        }}
                        onChangePosition={({ offsetPosition }) => {
                          const pixelDifferentWidthPrevPixel = (widthResponsiveRef.current || 0) - offsetPosition.x * 2
                          const limitPixelDifferentWidthPrevPixel =
                            pixelDifferentWidthPrevPixel > 320 ? pixelDifferentWidthPrevPixel : 320
                          const resultLimitPixelDifferentWidthPrevPixel = limitPixelDifferentWidthPrevPixel
                          setWidthTemporaryResponsive(resultLimitPixelDifferentWidthPrevPixel)

                          return {
                            resultSizes: {
                              x: resultLimitPixelDifferentWidthPrevPixel,
                            },
                          }
                        }}
                        onEndChangePosition={({ resultPosition }) => {
                          setWidthTemporaryResponsive(undefined)
                          if (resultPosition.x) setWidthResponsive(resultPosition.x)

                          activeResponsiveModeCenter.setIsDrag({ isDrag: false })
                        }}
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignContent: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'ew-resize',
                        }}
                        onDrag={({ is }) => {
                          setWidthIsDrag(is)
                        }}
                      >
                        <IconArrowsHorizontal
                          css={{
                            // transform: 'rotate(90deg)',
                            pointerEvents: 'none',
                          }}
                          color={colors.blue}
                          size={18}
                        />
                      </DragOffsetPosition>
                    </div>

                    <div
                      style={{
                        width: widthTemporaryResponsive ? widthTemporaryResponsive : widthResponsive,
                        maxWidth: '100%',
                        minWidth: '320px',
                        display: 'flex',
                        height: '100%',
                      }}
                    ></div>
                    <div
                      style={{
                        width: dragWidthPX,
                        minWidth: dragWidthPX,
                        borderLeft: '1px solid #EFEFEF',
                        borderRight: '1px solid #EFEFEF',
                        height: '100%',
                      }}
                    >
                      <DragOffsetPosition
                        limited={true}
                        onStart={() => {
                          activeResponsiveModeCenter.setIsDrag({ isDrag: true })
                        }}
                        onChangePosition={({ offsetPosition }) => {
                          const pixelDifferentWidthPrevPixel = (widthResponsiveRef.current || 0) + offsetPosition.x * 2
                          const limitPixelDifferentWidthPrevPixel =
                            pixelDifferentWidthPrevPixel > 320 ? pixelDifferentWidthPrevPixel : 320
                          const resultLimitPixelDifferentWidthPrevPixel = limitPixelDifferentWidthPrevPixel
                          setWidthTemporaryResponsive(resultLimitPixelDifferentWidthPrevPixel)

                          return {
                            resultSizes: {
                              x: resultLimitPixelDifferentWidthPrevPixel,
                            },
                          }
                        }}
                        onEndChangePosition={({ resultPosition }) => {
                          setWidthTemporaryResponsive(undefined)
                          if (resultPosition.x) setWidthResponsive(resultPosition.x)

                          activeResponsiveModeCenter.setIsDrag({ isDrag: false })
                        }}
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignContent: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'ew-resize',
                        }}
                        onDrag={({ is }) => {
                          setWidthIsDrag(is)
                        }}
                      >
                        <IconArrowsHorizontal
                          css={{
                            // transform: 'rotate(90deg)',
                            pointerEvents: 'none',
                          }}
                          color={colors.blue}
                          size={18}
                        />
                      </DragOffsetPosition>
                    </div>
                  </div>
                  <div
                    style={{
                      width: `100%`,
                      height: `calc(100% - ${responsiveHeight}px)`,
                      maxHeight: `calc(100% - ${responsiveHeight}px)`,
                      position: 'relative',
                    }}
                    onMouseLeave={() => activeResponsiveModeCenter.setIsDrag({ isDrag: true })}
                    onMouseMove={() => activeResponsiveModeCenter.setIsDrag({ isDrag: false })}
                  >
                    <div
                      style={{
                        zIndex: 2,
                        position: 'absolute',
                        width:
                          responsiveMode == 'Mobile'
                            ? breakpointsBox.mobile
                            : responsiveMode == 'Tablet'
                              ? breakpointsBox.tablet
                              : breakpointsBox.desktop,
                        height: '100%',
                        top: 0,
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        borderRight: '1px dashed red',
                        borderLeft: '1px dashed red',
                        pointerEvents: 'none',
                        opacity: 0.3,
                      }}
                    />

                    {responsiveMode == 'Desktop' ? (
                      <div
                        style={{
                          zIndex: 2,
                          position: 'absolute',
                          width: 1920,
                          height: '100%',
                          top: 0,
                          left: '50%',
                          transform: 'translate(-50%, 0)',
                          borderRight: '1px dashed red',
                          borderLeft: '1px dashed red',
                          pointerEvents: 'none',
                          opacity: 0.3,
                        }}
                      />
                    ) : null}

                    {activePopupID ? (
                      <PopupBoxEditorSync
                        popupID={activePopupID}
                        width={(widthTemporaryResponsive ? widthTemporaryResponsive : widthResponsive) || 1920}
                      />
                    ) : null}
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                        overflowX: 'hidden',
                        background: '#80808005',
                        overflowY: 'auto',
                      }}
                      ref={pageSectionsRef}
                      className={classes.hideScrollbar}
                    >
                      <div
                        className={classes.hideScrollbar}
                        ref={boxesWidthRef}
                        style={{
                          width: widthTemporaryResponsive ? widthTemporaryResponsive : widthResponsive,
                          maxWidth: '100%',
                          minWidth: '320px',
                          // height: '100%',
                          // maxHeight: '100%',
                          pointerEvents: widthTemporaryResponsive ? 'none' : undefined,
                        }}
                      >
                        <div
                          ref={boxesRef}
                          style={{
                            width: `100%`,
                            boxShadow: '0px 0px 19px 4px rgb(0, 0, 0, 0.11)',
                            background: 'white',
                            // height: '100%',
                          }}
                        >
                          {/* <div
                            css={{
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                            > */}

                          <List
                            // lockVertically
                            values={sectionsPinnedCollection}
                            onChange={({ oldIndex, newIndex }) => {
                              const updatedSectionsPinnedCollection = arrayMove(
                                page.sectionsPinnedCollection,
                                oldIndex,
                                newIndex
                              )
                              setSectionsPinnedCollection(updatedSectionsPinnedCollection)
                              updateSectionsPinnedSync.mutate({
                                sectionsPinnedIDs: updatedSectionsPinnedCollection.map((box, index) => {
                                  return {
                                    id: box.id,
                                    order: index,
                                  }
                                }),
                                pageID: page.id,
                              })
                            }}
                            renderList={({ children, props }) => <div {...props}>{children}</div>}
                            renderItem={({ value, props }) => (
                              <div
                                key={value.id}
                                css={{
                                  zIndex: 10000,
                                  position: 'relative',
                                }}
                                {...props}
                              >
                                <SectionCollectionSync
                                  table={{
                                    rowID: tableFirstRow?.id,
                                    tableID: page.pinTable?.id,
                                    tableDataID: tableData?.id,
                                  }}
                                  onUnpin={() => {
                                    deleteSectionsPinned.mutate({
                                      sectionsPinnedID: value.id,
                                    })
                                  }}
                                  key={value.id}
                                  parentBoxWidth={width}
                                  widthIsDrag={widthIsDrag}
                                  point={{
                                    modelID: value.id,
                                    selectID: value.selectID,
                                  }}
                                  parentRef={pageSectionsRef}
                                />
                              </div>
                            )}
                          />

                          {/* </div> */}

                          <Group p={'xs'} css={{ width: '100%' }} position="center">
                            <Menu
                              width={250}
                              opened={menuIsOpen}
                              onClose={() => setMenuIsOpen(false)}
                              transitionDuration={0}
                              withinPortal={true}
                              shadow="md"
                            >
                              <Menu.Target>
                                <div>
                                  <AccentButton
                                    loading={createBoxBlockWithPagePinAndSectionCreate.isLoading}
                                    onContextMenu={event => {
                                      event.preventDefault()
                                      setChooseSectionsOpened(false)

                                      setMenuIsOpen(o => !o)
                                    }}
                                    onClick={() => {
                                      setMenuIsOpen(false)
                                      setChooseSectionsOpened(o => !o)
                                    }}
                                    leftIcon={<IconCirclePlus />}
                                    css={
                                      page.sectionsPinnedCollection.length
                                        ? undefined
                                        : css`
                                            animation: ${animationEmotionBounce} 2s ease infinite;
                                          `
                                    }
                                  >
                                    добавить блок на страницу
                                  </AccentButton>
                                  {chooseSectionsOpened ? (
                                    <Modal
                                      withinPortal={true}
                                      opened={chooseSectionsOpened}
                                      onClose={() => setChooseSectionsOpened(false)}
                                      size={1300}
                                      className={classes.hideScrollbar}
                                      // @ts-ignore
                                      ref={containerRef}
                                      title={
                                        <Group position="center">
                                          <AccentButton
                                            loading={createBoxBlockWithPagePinAndSectionCreate.isLoading}
                                            leftIcon={<IconPlus size={20} />}
                                            onClick={async () => {
                                              await createBoxBlockWithPagePinAndSectionCreate.mutateAsync({
                                                pageID: page.id,
                                                siteID: site.id,
                                                order: page.sectionsPinnedCollection.length,
                                                mode: activeResponsiveModeCenter.getResponsiveMode(),
                                              })
                                              setChooseSectionsOpened(false)
                                            }}
                                          >
                                            Добавить чистый блок
                                          </AccentButton>
                                          {[
                                            { name: 'БЛОКИ САЙТА', value: 'site' },
                                            { name: 'ШАБЛОННЫЕ БЛОКИ', value: 'template' },
                                            { name: 'БЛОКИ С ДРУГИХ САЙТОВ', value: 'other' },
                                          ].map(item => (
                                            <GrayButton
                                              leftIcon={currentBlocks == item.value ? <IconArrowRight /> : undefined}
                                              white={currentBlocks == item.value ? false : true}
                                              onClick={() => {
                                                setCurrentBlocks(item.value)
                                              }}
                                            >
                                              {item.name}
                                            </GrayButton>
                                          ))}
                                        </Group>
                                      }
                                      styles={
                                        {
                                          // modal: {
                                          //   maxWidth: 'calc(100vw - 50px)',
                                          // },
                                          // header: {
                                          //   display: 'none',
                                          // },
                                          // body: {
                                          //   backgroundColor: 'white !important',
                                          //   borderRadius: '2px',
                                          //   padding: '20px',
                                          // },
                                        }
                                      }
                                    // centered={true}
                                    >
                                      {/* <SimpleGrid cols={1}>
                    <Text>Блоки из коллекции со всех сайтов</Text>
                    <SimpleGrid cols={3}>
                      {site.user.favoriteBoxBlocks.map(boxBlock => (
                        <div
                          key={boxBlock.id}

                          // css={{ cursor: 'pointer' }}
                        >
                          <SimpleGrid spacing={6} cols={1}>
                            <ReduceComponent component={<BoxBlock key={boxBlock.id} {...boxBlock.box} />} />
                            <Button
                              onClick={() => {
                                setChooseSectionsOpened(false)
                                copyFavoriteBoxBlock.mutate({
                                  boxBlockID: boxBlock.id,
                                  pageID: page.id,
                                })
                              }}
                              compact
                            >
                              Копировать
                            </Button>
                          </SimpleGrid>
                        </div>
                      ))}
                    </SimpleGrid>
                  </SimpleGrid> */}
                                      <SimpleGrid
                                        css={{
                                          alignItems: 'flex-start',
                                          alignContent: 'start',
                                        }}
                                        cols={1}
                                        breakpoints={[{ maxWidth: 'md', cols: 1 }]}
                                      >
                                        {currentBlocks == 'site' ? (
                                          <>
                                            {site.withBlockGroups ? (
                                              <BlockGroupsSync
                                                parentRef={containerRef}
                                                onChooseSection={async ({ sectionsID }) => {
                                                  await createSectionsPinned.mutateAsync({
                                                    pageID: page.id,
                                                    sectionsID,
                                                    order: sectionsPinnedCollectionRef?.current?.length || 100,
                                                  })
                                                  setChooseSectionsOpened(false)
                                                  // setChooseSectionsOpened(false)
                                                }}
                                              />
                                            ) : (
                                              <SiteBlocksSync
                                                parentRef={containerRef}
                                                onChooseBlock={async ({ blockID }) => {
                                                  await createSectionsPinnedWithBoxBlock.mutateAsync({
                                                    pageID: page.id,
                                                    boxBlockID: blockID,
                                                    order: sectionsPinnedCollectionRef?.current?.length || 100,
                                                  })
                                                  setChooseSectionsOpened(false)
                                                }}
                                              />
                                            )}
                                          </>
                                        ) : null}

                                        {currentBlocks == 'template' ? (
                                          <BlockCollections
                                            onCopied={() => {
                                              setChooseSectionsOpened(false)
                                            }}
                                            parentRef={containerRef}
                                            pageID={page.id}
                                          />
                                        ) : null}
                                        {currentBlocks == 'other' ? (
                                          <MySiteBlockCollections
                                            onCopied={() => {
                                              setChooseSectionsOpened(false)
                                            }}
                                            parentRef={containerRef}
                                            pageID={page.id}
                                          />
                                        ) : null}
                                      </SimpleGrid>
                                    </Modal>
                                  ) : null}
                                </div>
                              </Menu.Target>

                              <Menu.Dropdown
                                className={classes.hideScrollbar}
                                css={{
                                  maxHeight: 400,
                                  overflowY: 'auto',
                                }}
                              >
                                <div
                                  onClick={event => {
                                    // event.stopPropagation()
                                    setMenuIsOpen(false)
                                  }}
                                >
                                  <Menu.Label>Добавить на страницу</Menu.Label>
                                  <Menu.Item
                                    onClick={() => {
                                      createBoxBlockWithPagePinAndSectionCreate.mutate({
                                        pageID: page.id,
                                        siteID: site.id,
                                        order: page.sectionsPinnedCollection.length,
                                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                                      })
                                    }}
                                  >
                                    Добавить чистый блок
                                  </Menu.Item>
                                  {/* <Menu.Item onClick={() => {}}>two</Menu.Item> */}
                                </div>
                              </Menu.Dropdown>
                            </Menu>
                          </Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <DndContext> */}
                {/* <div
                    className={classes.hideScrollbar}
                    css={{
                      width: settingsWidth,
                      height: 'calc(100%)',
                      // maxHeight: '100%',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      // borderLeft: '1px solid #EFEFEF',

                      // borderTop: '1px solid #EFEFEF',
                    }}
                  >
                  </div> */}

                {/* </DndContext> */}
                <LayerSettingsSyncMemo pageID={page.id} />
                <BoxSettingsSync />
              </div>
            </MyBoxBlockContext.Provider>
          </div>

          <div
            css={
              {
                // padding: '12px',
                // borderTop: '1px solid #EFEFEF'
              }
            }
            ref={boxHeightBottomRef}
          >
            {/* <div
              css={{
                display: 'flex',
              }}
            >
              <div
                css={{
                  width: settingsWidth,
                }}
              ></div>
              <Group
                position="center"
                css={{
                  width: `calc(100% - ${settingsWidth}px - ${layersWidth}px)`,
                }}
              >
                {entitiesRoutes.map(entity => (
                  <div
                    css={{
                      padding: '6px 12px',
                      background: colors.blue,
                      borderRadius: 2,
                      cursor: 'pointer',
                      transition: '0.1s',
                      ':hover': {
                        opacity: 0.75,
                        transform: 'scale(1.05)',
                      },
                    }}
                    onClick={() => {
                      switch (entity.path) {
                        case 'sections':
                          setSectionsOpened(true)
                          break
                        case 'blocks':
                          setBlocksOpened(true)
                          break
                        case 'events':
                          setEventsOpened(true)
                          break
                        case 'forms':
                          setFormsOpened(true)
                          break
                        case 'popups':
                          setPopupsOpened(true)
                          break
                        case 'quizzes':
                          setQuizzessOpened(true)
                          break
                        case 'targets':
                          setTargetsOpened(true)
                          break
                        case 'colors':
                          setColorsOpened(true)
                          break

                        default:
                          break
                      }
                    }}
                  >
                    <Text color={'white'} size={'sm'}>
                      {entity.name}
                    </Text>
                  </div>
                ))}
              </Group>
            </div> */}
          </div>
        </div>
      </>
    )
  }

  return <> {pageID}</>
}

type TableProps = {
  rowID?: string
  tableID?: string
  tableDataID?: string
}

function SectionCollectionSync({
  parentBoxWidth,
  onUnpin,
  widthIsDrag,
  point,
  parentRef,
  table,
}: {
  onUnpin: () => void
  parentBoxWidth: number
  widthIsDrag: boolean
  point: {
    modelID: string
    selectID: 'SectionsPinned1'
  }
  parentRef?: RefObject<HTMLDivElement>
  table?: TableProps
}) {
  const sectionPinnedCollection = useGetSelectData({ modelID: point.modelID, selectID: point.selectID })
  const deleteSectionsPinned = trpc.useMutation('user.deleteSectionsPinned', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateSectionsPinned = trpc.useMutation('user.updateSectionsPinned', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const sectionsCollection = useGetSelectData({
    modelID: sectionPinnedCollection.sectionsCollection.id,
    selectID: sectionPinnedCollection.sectionsCollection.selectID,
  })

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        // order: sectionPinnedCollection.order,
      }}
      section-pinned-id={point.modelID}
    >
      {/* {isOver ? (
        <div
          css={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            border: 'purple',
          }}
        />
      ) : null} */}
      <div>
        <div css={{ position: 'absolute', right: 10, top: 10, zIndex: 10, display: 'flex', gap: 8 }}>
          <Paper shadow="xs">
            <ActionIcon size={'xs'} color="grape" variant="light" data-movable-handle>
              <IconArrowsMoveVertical style={{ cursor: 'ns-resize' }} />
            </ActionIcon>
          </Paper>

          <Paper shadow="xs">
            <ActionIcon size={'xs'} color="red" variant="light">
              <IconX
                css={{
                  cursor: 'not-allowed',
                }}
                onClick={() => {
                  onUnpin()
                }}
                width={20}
              />
            </ActionIcon>
          </Paper>

          <Paper shadow="xs">
            <ActionIcon title={'Прибить'} size={'xs'} color="purple" variant="light">
              {sectionPinnedCollection.isPinned ? (
                <IconPinnedOff
                  css={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    updateSectionsPinned.mutate({
                      sectionsPinnedID: sectionPinnedCollection.id,
                      isPinned: false,
                    })
                  }}
                  width={20}
                />
              ) : (
                <IconPinned
                  css={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    updateSectionsPinned.mutate({
                      sectionsPinnedID: sectionPinnedCollection.id,
                      isPinned: true,
                    })
                  }}
                  width={20}
                />
              )}
            </ActionIcon>
          </Paper>
        </div>

        {sectionsCollection.sectionsChild.map(section => {
          // if (section.boxBlock) {
          //   return (
          //     <InView>
          //       {({ ref, inView }) => (
          //         <div ref={ref}>
          //           {section.boxBlock ? (
          //             inView && !widthIsDrag ? (
          //               <Box
          //                 key={section.id}
          //                 onAddBox={({ box }) => {
          //                   // setBoxes(boxes => {
          //                   //   return Array.isArray(boxes) ? [...boxes, box] : boxes
          //                   // })
          //                 }}
          //                 onDeleteBox={({ id }) => {
          //                   // setBoxes(boxes.filter(box => box.id !== id))
          //                 }}
          //                 boxBlockID={section.boxBlock.id}
          //                 parentBoxWidth={parentBoxWidth}
          //                 box={section.boxBlock.box}
          //               />
          //             ) : (
          //               <BoxBlock responsiveMode={responsiveMode} {...section.boxBlock.box} />
          //             )
          //           ) : (
          //             <></>
          //           )}
          //         </div>
          //       )}
          //     </InView>
          //   )
          // }
          return (
            <SectionsSync
              onDeleteRootBoxInBlock={() => { }}
              key={section.id}
              width={parentBoxWidth}
              modelID={section.id}
              selectID={section.selectID}
              parentRef={parentRef}
              table={table}
            />
          )
        })}
      </div>
    </div>
  )
}

export const SectionsSync = ({
  modelID,
  selectID,
  width,
  hideLine,
  onDeleteRootBoxInBlock,
  parentRef,
  table,
}: {
  modelID: string
  selectID: 'Section1'
  width: number
  hideLine?: true
  onDeleteRootBoxInBlock?: () => void
  parentRef?: RefObject<HTMLDivElement>
  table?: TableProps
}) => {
  const section = useGetSelectData({ modelID, selectID })

  return (
    <>
      {section.boxBlock ? (
        <BoxBlockSync
          onDeleteRootBoxInBlock={() => {
            if (onDeleteRootBoxInBlock) onDeleteRootBoxInBlock()
          }}
          hideLine={hideLine}
          width={width}
          modelID={section.boxBlock.id}
          selectID={section.boxBlock.selectID}
          parentRef={parentRef}
          table={table}
        />
      ) : (
        'boxBlock not found'
      )}
    </>
  )
}

export const BoxBlockSync = ({
  modelID,
  selectID,
  width,
  hideLine,
  onDeleteRootBoxInBlock,
  parentRef,
  table,
}: {
  modelID: string
  selectID: 'BoxBlock1'
  width: number
  hideLine?: true
  onDeleteRootBoxInBlock?: () => void
  parentRef?: RefObject<HTMLDivElement>
  table?: TableProps
}) => {
  const boxBlock = useGetSelectData({ modelID, selectID })

  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const { ref, entry } = useIntersection({
    root: parentRef?.current,
    threshold: 0.05,
  })

  // console.log({
  //   isIntersecting: entry?.isIntersecting,
  //   parentRef: parentRef?.current,
  // })

  return (
    <>
      <div
        ref={ref}
        css={{
          // border: '2px solid green',
          // background: entry ? (entry.isIntersecting ? 'pink' : undefined) : undefined,
          transition: '3s',
        }}
      >
        {/* <DndContext> */}
        {/* <BoxSyncV2 modelID={boxBlock.box.id} selectID={boxBlock.box.selectID} /> */}
        <BoxSyncMemoV2
          // boxBlockID={boxBlock.id}
          onAddBox={() => { }}
          rowID={table?.rowID}
          tableContextID={table?.tableID}
          tableDataContextID={table?.tableDataID}
          onDeleteBox={() => {
            if (onDeleteRootBoxInBlock) onDeleteRootBoxInBlock()
          }}
          getParentBoxWidth={() => {
            return width
          }}
          getParentBoxHeight={() => {
            return 0
          }}
          modelID={boxBlock.box.id}
          selectID={boxBlock.box.selectID}
          hideLine={hideLine}
          isTransition={entry ? !entry.isIntersecting : false}
          firstLevelOfBox={true}
        />
        {/* {userIsProgrammer(user.login) ? (
          
        ) : (
          <BoxSyncMemo
            // boxBlockID={boxBlock.id}
            onAddBox={() => {}}
            onDeleteBox={() => {
              if (onDeleteRootBoxInBlock) onDeleteRootBoxInBlock()
            }}
            getParentBoxWidth={() => {
              return width
            }}
            getParentBoxHeight={() => {
              return 0
            }}
            modelID={boxBlock.box.id}
            selectID={boxBlock.box.selectID}
            hideLine={hideLine}
          />
        )} */}

        {/* </DndContext> */}
      </div>
    </>
  )
}

const AddSettingsAtPanelSync = ({ boxID }: { boxID: string }) => {
  const box = useGetSelectData({ modelID: boxID, selectID: 'Box1' })
  const createBox = trpc.useMutation('user.box.createBox', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [thereIsComponent, setThereIsComponent, thereIsComponentRef] = useStateRef(
    !!(
      (box.textComponent || box.imageComponent || box.formComponent || box.quizComponent || box.videoComponent)
      // box.videoComponent
    )
  )

  useEffect(() => {
    setThereIsComponent(
      !!(
        box.textComponent ||
        box.imageComponent ||
        box.formComponent ||
        box.quizComponent ||
        box.videoComponent ||
        box.faqComponent
      )
    )
  }, [box.textComponent, box.imageComponent, box.formComponent, box.faqComponent, box.quizComponent, box.videoComponent])

  const pinTextComponentToBox = trpc.useMutation('user.box.pinTextComponentToBox', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [imagesOpened, setImagesOpened] = useState(false)

  const createImageComponent = trpc.useMutation('user.box.createImageComponent', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateImageComponent = trpc.useMutation('user.box.updateImageComponent', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const changeImageComponentImage = trpc.useMutation('user.box.changeImageComponentImage', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createVideoComponent = trpc.useMutation('user.box.createVideoComponent', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const deleteBox = trpc.useMutation('user.box.deleteBox', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const generateText = trpc.useMutation('user.box.generateText', {
    onSuccess: data => {
      showNotification({
        title: `Списано ${data.costInRubles}`,
        message: '',
        color: 'green',
      })
    },
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  return (
    <>
      <Group>
        <Group
          onClick={async () => {
            await deleteBox.mutate({
              boxID: box.id,
            })
            boxActiveIDCenter.setActiveID({ boxID: undefined })
          }}
          css={{
            background: `rgba(255,255,255, 0.3)`,
            borderRadius: '3px',
            padding: '4px 7px',
            cursor: 'pointer',
            ':hover': {
              background: `rgba(255,255,255, 0.2)`,
            },
          }}
          spacing={6}
        >
          <IconBoxOff
            css={{
              display: 'inline-block',
            }}
            size={18}
            color={'white'}
          />
        </Group>
        {thereIsComponent ? (
          box.textComponent ? (
            <>
              {/* <Group
                onClick={async () => {
                  if (box.textComponent) {
                    const request = prompt('Введите что нужно сгенерировать')
                    if (request) {
                      await generateText.mutateAsync({
                        textComponentID: box.textComponent.id,
                        request: request,
                      })
                    }
                  }
                }}
                css={{
                  background: `rgba(255,255,255, 0.3)`,
                  borderRadius: '3px',
                  padding: '4px 7px',
                  cursor: 'pointer',
                  ':hover': {
                    background: `rgba(255,255,255, 0.2)`,
                  },
                }}
                spacing={6}
              >
                <Text size="xs" color="white" weight={'bold'}>
                  сгенерировать текст
                </Text>
              </Group> */}
            </>
          ) : null
        ) : (
          <>
            <Group
              onClick={async () => {
                if (!thereIsComponentRef.current) {
                  const newBox = await createBox.mutateAsync({
                    boxID: box.id,
                    positionType: 'Relative',
                    order: (box.boxes ? box.boxes.length + 1 : 100) + 1,
                    mode: activeResponsiveModeCenter.getResponsiveMode(),
                  })
                }
              }}
              css={{
                background: `rgba(255,255,255, 0.3)`,
                borderRadius: '3px',
                padding: '4px 7px',
                cursor: 'pointer',
                ':hover': {
                  background: `rgba(255,255,255, 0.2)`,
                },
              }}
              spacing={6}
            >
              <IconPlus
                css={{
                  display: 'inline-block',
                }}
                size={18}
                color={'white'}
              />
              <IconBox
                css={{
                  display: 'inline-block',
                }}
                size={18}
                color={'white'}
              />
            </Group>

            {box.boxes?.length ? null : (
              <>
                <ImagesSync
                  opened={imagesOpened}
                  onClose={() => setImagesOpened(false)}
                  imageChoosen={async ({ imageID, imageName }) => {
                    if (box.imageComponent) {
                      await changeImageComponentImage.mutateAsync({
                        imageComponentID: box.imageComponent.id,
                        imageID: imageID,
                      })
                    } else {
                      await createImageComponent.mutateAsync({
                        boxID: box.id,
                        imageID: imageID,
                      })
                    }
                    setImagesOpened(false)
                    // setImageComponentEditOpened(true)
                  }}
                />

                <Group
                  onClick={async () => {
                    const updatedBox = await pinTextComponentToBox.mutateAsync({
                      boxID: box.id,
                      type: 'paragraph',
                      mode: activeResponsiveModeCenter.getResponsiveMode(),
                    })
                  }}
                  css={{
                    background: `rgba(255,255,255, 0.3)`,
                    borderRadius: '3px',
                    padding: '4px 7px',
                    cursor: 'pointer',
                    ':hover': {
                      background: `rgba(255,255,255, 0.2)`,
                    },
                  }}
                  spacing={6}
                >
                  <IconTextSize
                    css={{
                      display: 'inline-block',
                    }}
                    size={18}
                    color={'white'}
                  />
                </Group>

                <Group
                  onClick={async () => {
                    setImagesOpened(true)
                  }}
                  css={{
                    background: `rgba(255,255,255, 0.3)`,
                    borderRadius: '3px',
                    padding: '4px 7px',
                    cursor: 'pointer',
                    ':hover': {
                      background: `rgba(255,255,255, 0.2)`,
                    },
                  }}
                  spacing={6}
                >
                  <IconPhoto
                    css={{
                      display: 'inline-block',
                    }}
                    size={18}
                    color={'white'}
                  />
                </Group>

                <Group
                  onClick={() => {
                    createVideoComponent.mutate({
                      boxID: box.id,
                      videoURL: '',
                    })
                  }}
                  css={{
                    background: `rgba(255,255,255, 0.3)`,
                    borderRadius: '3px',
                    padding: '4px 7px',
                    cursor: 'pointer',
                    ':hover': {
                      background: `rgba(255,255,255, 0.2)`,
                    },
                  }}
                  spacing={6}
                >
                  <IconVideo
                    css={{
                      display: 'inline-block',
                    }}
                    size={18}
                    color={'white'}
                  />
                </Group>
              </>
            )}
          </>
        )}
      </Group>
    </>
  )
}
